<script>
	import GlobalVue from '@helper/Global.vue';
	export default { extends: GlobalVue, };
</script>
<template>
	<section id='loader'>
        <div class='loader-group'>
            <img :src="assets('fo_images','wave_1.svg')" class='wave'>
            <img :src="assets('fo_images','ts-2019.png')" class='logo'>
        </div>
    </section>
</template>